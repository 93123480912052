const hljs = require('highlight.js/lib/core')

const javascript = require('highlight.js/lib/languages/javascript')
hljs.registerLanguage('javascript', javascript)

const css = require('highlight.js/lib/languages/css')
hljs.registerLanguage('css', css)

const scss = require('highlight.js/lib/languages/scss')
hljs.registerLanguage('scss', scss)

const erb = require('highlight.js/lib/languages/erb')
hljs.registerLanguage('erb', erb)

const python = require('highlight.js/lib/languages/python')
hljs.registerLanguage('python', python)

const php = require('highlight.js/lib/languages/php')
hljs.registerLanguage('php', php)

const ruby = require('highlight.js/lib/languages/ruby')
hljs.registerLanguage('ruby', ruby)

const json = require('highlight.js/lib/languages/json')
hljs.registerLanguage('json', json)

const xml = require('highlight.js/lib/languages/xml')
hljs.registerLanguage('xml', xml)

const http = require('highlight.js/lib/languages/http')
hljs.registerLanguage('http', http)

const mergeHTMLPlugin = require('../vendor/hljs/mergeHTMLPlugin')

hljs.configure({
  hideUpgradeWarningAcceptNoSupportOrSecurityUpdates: true,
  ignoreUnescapedHTML: true,
  throwUnescapedHTML: false,
  tabReplace: '  ',
  languages: ['javascript', 'css', 'scss', 'erb', 'php', 'python', 'ruby', 'json', 'xml', 'http'],
})

hljs.addPlugin(mergeHTMLPlugin)

module.exports = hljs
