const toVideoPlayerOptions = require('../lib/toVideoPlayerOptions')
const VimeoVideoPlayer = require('./vimeoVideoPlayer')
const BaseModule = require('_base/base')

class VimeoVideo extends BaseModule {
  preRender() {
    if (!this.player) {
      const el = this.$el[0]
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      this.player = new VimeoVideoPlayer(el, options)
    }
  }
}

document.querySelectorAll('.vimeoVideo').forEach(el => new VimeoVideo($(el)))
