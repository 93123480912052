import { Component } from 'preact'
import cx from 'classnames'

import JSON_HEADERS from 'jsonHeaders'
import { initRecaptcha, executeRecaptcha } from 'lib/recaptcha'
import EmailForm from '../emailForm'
import { Caption } from '../textNodes'

import dummySanitize from 'lib/utils/dummySanitize'


class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: null,
      hasRequestedEmail: false,
    }

    this.requestLoginEmail = this.requestLoginEmail.bind(this)
    this.sendLoginEmail = this.sendLoginEmail.bind(this)
  }

  componentDidMount() {
    initRecaptcha()
  }

  requestLoginEmail({ email }) {
    return executeRecaptcha({ action: 'login' })
      .then(token => this.sendLoginEmail({ email, token }))
  }

  sendLoginEmail({ email, token }) {
    return fetch('/sessions/', {
      method: 'POST',
      headers: JSON_HEADERS,
      body: JSON.stringify({ email, 'g-recaptcha-response-data': token }),
    })
      .then(() => this.setState({ email, hasRequestedEmail: true }))
      .catch(console.error)
  }

  render() {
    const { hasRequestedEmail, email } = this.state
    const shouldDisplayHeading = this.props.skipHeading !== true

    return (
      <div className="loginForm">
        { shouldDisplayHeading &&
        <div className="loginForm-heading">
          <b>{ I18n.t('userPopup.loginToBureausphere') }</b>
        </div>
        }

        <EmailForm onSubmit={ this.requestLoginEmail } />

        <Caption
          className={ cx({ is__visible: hasRequestedEmail }) }
          html={ I18n.t('emailForm.authenticationEmailSent', { email: dummySanitize(email) }) }
        />
      </div>
    )
  }
}

export default LoginForm
