const toVideoPlayerOptions = require('../lib/toVideoPlayerOptions')
const ZenVideoPlayer = require('./zenVideoPlayer')
const BaseModule = require('_base/base')

class ZenVideo extends BaseModule {
  preRender() {
    if (!this.player) {
      const el = this.$el[0]
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      this.player = new ZenVideoPlayer(el, options)
    }
  }
}

document.querySelectorAll('.zenVideo').forEach(el => new ZenVideo($(el)))
