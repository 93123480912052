const DEFAULT_PLAYBACK_RATE = 1
const DEFAULT_START_TIME = 0

const serialize = JSON.stringify
const deserialize = JSON.parse

const readState = (scope) => {
  try {
    return deserialize(localStorage.getItem(scope)) || {}
  } catch (e) {
    console.warn('Failed to retrieve initial state: ', e) // eslint-disable-line no-console
  }

  return {}
}

const persistState = (scope, state) => {
  try {
    localStorage.setItem(scope, serialize(state))
  } catch (e) {
    console.warn('Failed to persist state: ', e) // eslint-disable-line no-console
  }
}

class VideoPlayerState {
  constructor({ id }) {
    this.id = id
    this.scope = 'videoPlayersState'
  }

  get currentState() {
    return readState(this.scope) || {}
  }

  get progress() {
    const data = this.currentState[this.id]

    return Math.floor(data?.seconds || data?.progress || DEFAULT_START_TIME)
  }

  saveProgress({ seconds, duration }) {
    const state = Object.assign(this.currentState, { [this.id]: { seconds, duration } })

    persistState(this.scope, state)

    this.triggerProgressEvent({ seconds, duration })
  }

  get playbackRate() {
    return this.currentState.playbackRate || DEFAULT_PLAYBACK_RATE
  }

  savePlaybackRate(playbackRate) {
    const state = Object.assign(this.currentState, { playbackRate })

    persistState(this.scope, state)
  }

  triggerProgressEvent({ seconds, duration }) {
    const event = new CustomEvent('videoProgressUpdate', { detail: { seconds, duration, id: this.id } })

    document.dispatchEvent(event)
  }
}

module.exports = VideoPlayerState
