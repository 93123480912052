import { Component } from 'preact'
import cx from 'classnames'

import { Cols } from '../cols'
import { Heading1 } from '../textNodes'
import { SlideInLayer } from '../slideInLayer'
import LoginForm from '../userPopup/loginForm'

export default class LoginBar extends Component {
  constructor(props) {
    super(props)

    this.state = { isVisible: true }

    this.hide = this.hide.bind(this)
  }

  hide() {
    this.setState({ isVisible: false })
  }

  render() {
    const className = cx('loginBar', { is__visible: this.state.isVisible })

    return (
      <div className={ className }>
        <SlideInLayer isVisible={ this.state.isVisible } setHidden={ this.hide }>
          <div className="holder">
            <div className="module is__boxed is__bleedBackgroundSides textBox">
              <Cols transposeOnMobile className="is__desktopDivisionProportions6to10">
                <div className="loginBar-description">
                  <Heading1 className="loginBar-header" html={ I18n.t('loginBar.heading') } />
                </div>
                <div className="loginBar-form">
                  <LoginForm skipHeading />
                </div>
              </Cols>
            </div>
          </div>
        </SlideInLayer>
      </div>
    )
  }
}
