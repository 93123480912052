import JSON_HEADERS from 'jsonHeaders'

import { executeRecaptcha } from 'recaptcha'

const queryPreparationApi = ({ email, productId, subscriptionType, token, coupon, meta }) => {
  const preparationUrl = `/api/system/products/${productId}/subscription/preparation/`

  return fetch(preparationUrl, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ email, subscriptionType, token, coupon, meta }),
    headers: JSON_HEADERS,
  })
    .then(res => res.json())
}

module.exports = (params) => {
  return executeRecaptcha({ action: 'preparation' })
    .then(token => queryPreparationApi({ ...params, token }))
}
