const loadScriptOnce = require('load-script-once')

const RECAPTCHA_KEY = '6LeTUVgeAAAAABo9OnX6IL8_8lEU3SCPtuBgwYAP'

const initRecaptcha = () => loadScriptOnce(`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`)

const executeRecaptcha = (options = {}) => {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(RECAPTCHA_KEY, { action: options.action || 'form_submission' })
        .then(resolve)
        .catch(reject)
    })
  })
}

module.exports = {
  RECAPTCHA_KEY,
  initRecaptcha,
  executeRecaptcha,
}
