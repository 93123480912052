const toVideoPlayerOptions = require('../lib/toVideoPlayerOptions')
const KinescopeVideoPlayer = require('./kinescopeVideoPlayer')

const BaseModule = require('_base/base')

class KinescopeVideo extends BaseModule {
  preRender() {
    if (!this.player) {
      const el = this.$el[0]
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      this.player = new KinescopeVideoPlayer(el, options)
    }
  }
}

document.querySelectorAll('.kinescopeVideo').forEach(el => new KinescopeVideo($(el)))
