const BaseModule = require('_base/base')

class EmbeddedVideoCover extends BaseModule {
  preInit() {
    this.$video = this.$el.closest('.embeddedVideo')
    this.video = this.$video[0]

    this.$el.on('click', this.revealAndPlayVideo.bind(this))
  }

  revealAndPlayVideo() {
    this.$video.addClass('is__active')
    this.video.dispatchEvent(new CustomEvent('playNow'))
  }
}

$('.js__embeddedVideoCover').each((_, el) => new EmbeddedVideoCover($(el)))
