import FormFieldsStorage from '../newPaybar/formFieldsStorage'
import { initRecaptcha, executeRecaptcha } from 'lib/recaptcha'

const DEFAULT_FORM_URL = '/pages/form_submissions'
const DEFAULT_FORM_ID = 'uv1kd2Ag30HDpYZQqfUIywlehENoiY4j0re7vmaAxYg'

const formFieldsStorage = new FormFieldsStorage('welcomeForm')

class WelcomeForm {
  constructor($el) {
    this.$el = $el
    this.$form = this.$el.find('form')
    this.$fields = this.$form.find('textarea, input')
    this.$requiredFields = this.$form.find('[required]')
    this.$button = this.$form.find('button')
    this.$draftCaption = this.$el.find('.welcomeForm-draftCaption')
    this.$errorCaption = this.$el.find('.welcomeForm-error')
    this.formUrl = this.$el.attr('data-form-url') || DEFAULT_FORM_URL
    this.formId = this.$el.attr('data-form-id') || DEFAULT_FORM_ID
    this.user = window.application.user || {}

    this.rememberedFields = formFieldsStorage.get() || {}

    this.addUserFields()
    this.useRememberedFields()

    if (this.isSaved) this.showDraftCaption()
    initRecaptcha()

    this.checkButtonAvailability()
    this.focusOnFirstInput()
    this.$form.on('input', this.onInput.bind(this))
    this.$form.on('submit', this.submitWelcomeForm.bind(this))
  }

  useRememberedFields() {
    Object.keys(this.rememberedFields).forEach(name => {
      const value = this.rememberedFields[name]
      if (value) this.$form.find(`[name='${name}']`).val(value)
    })
  }

  addUserFields() {
    const userFields = { name: this.user.name || '', email: this.user.email || '' }

    Object.keys(userFields).forEach(name => {
      this.rememberedFields[name] = this.rememberedFields[name] || userFields[name]
    })
  }

  get isValid() {
    return this.$requiredFields.get().every(el => el.validity.valid)
  }

  get isSaved() {
    return !(Object.keys(formFieldsStorage.get()).length === 0)
  }

  checkButtonAvailability() {
    this.$button.toggleClass('is__disabled', !this.isValid)
  }

  showDraftCaption() {
    this.$el.addClass('is__saved')
  }

  focusOnFirstInput() {
    const isEmpty = !this.$fields.first().val()

    // HACK: it doesn't work without timeout on textarea
    if (isEmpty) setTimeout(() => this.$fields.first().focus(), 1000)
  }

  onInput() {
    this.showDraftCaption()

    this.$fields.each((_, el) => {
      const $el = $(el)
      this.rememberedFields[$el.attr('name')] = $el.val()
    })
    formFieldsStorage.save(this.rememberedFields)

    this.checkButtonAvailability()
  }

  submitWelcomeForm(e) {
    e.preventDefault()

    executeRecaptcha()
      .then((token) => {
        const formData = new FormData(e.target)
        formData.append('g-recaptcha-response-data', token)
        formData.append('form_id', this.formId)
        const params = { method: 'POST', body: formData }

        return fetch(this.formUrl, params)
          .then(res => {
            if (!res.ok) throw Error(res.statusText)

            this.$el.addClass('is__success')
            formFieldsStorage.clear()
          })
          .catch((err) => {
            if (window.Sentry) Sentry.captureMessage(`Welcome form error: ${err}`)
            this.$el.addClass('is__error')
          })
      })
      .catch(() => {
        this.$el.addClass('is__error')
      })
  }
}

$('.js__welcomeForm').each(function() {
  new WelcomeForm($(this))
})
