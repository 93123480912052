const toVideoPlayerOptions = require('../lib/toVideoPlayerOptions')
const YoutubeVideoPlayer = require('./youtubeVideoPlayer')
const BaseModule = require('_base/base')

class YoutubeVideo extends BaseModule {
  preRender() {
    if (!this.player) {
      const el = this.$el[0]
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      this.player = new YoutubeVideoPlayer(el, options)
    }
  }
}

document.querySelectorAll('.youtubeVideo').forEach(el => new YoutubeVideo($(el)))

// Setup MutationObserver for dynamically added YouTube videos
const webpage = document.querySelector('.webpage')
if (webpage) {
  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      mutation.addedNodes.forEach(node => {
        if (node instanceof HTMLElement) {
          if (node.matches('.youtubeVideo-container')) YoutubeVideoPlayer.vitalize(node.parentNode)

          node
            .querySelectorAll('.youtubeVideo-container')
            .forEach(el => YoutubeVideoPlayer.vitalize(el.parentNode))
        }
      })
    })
  })

  observer.observe(webpage, { childList: true, subtree: true })
}
