import { setTextareaAutosize } from './helpers'
import { initRecaptcha, executeRecaptcha } from 'lib/recaptcha'

const INVALID_CLASS = 'is__invalid'
const LOADING_CLASS = 'is__loading'
const SUCCESS_CLASS = 'is__success'

export default class Form {
  constructor($form) {
    initRecaptcha()

    this.$form = $form
    this.$inputs = this.$form.find('input, textarea')
    this.$submit = this.$form.find('button[type="submit"]')

    this.id = this.$form.attr('form-id')
    this.submitUrl = this.$form.attr('action')
    this.method = this.$form.attr('method')
    this.onSuccess = window[this.$form.attr('on-success')]
    this.onError = window[this.$form.attr('on-error')]

    this.hasLocalStorageSupport = true

    this.$inputs.on('input', this.handleInput.bind(this))
    this.$inputs.on('blur', e => this.setInputValidity($(e.target)))
    this.$form.on('submit', this.submit.bind(this))

    this.restoreSavedValues()
    this.checkTotalValidity()
    this.setTextareaAutosize()
  }

  setLoading(isLoading = true) {
    this.isLoading = isLoading
    this.$form.toggleClass(LOADING_CLASS, isLoading)
  }

  setInputValidity($input) {
    const hasValue = $input.val().length

    if (!hasValue) return

    const isValid = $input[0].checkValidity()
    const $inputWrap = $input.parent()

    $inputWrap.toggleClass(INVALID_CLASS, !isValid)
  }

  checkTotalValidity() {
    const isAllValid = this.$inputs.toArray().every(input => input.checkValidity())

    this.$inputs.each((_, el) => { this.setInputValidity($(el)) })

    this.$submit.prop('disabled', !isAllValid)
  }

  handleInput(e) {
    this.saveValue($(e.target))
    this.setInputValidity($(e.target))

    this.checkTotalValidity()
  }

  handleSuccess(res) {
    this.$form.addClass(SUCCESS_CLASS)
    this.$form[0].reset()
    if (this.onSuccess) this.onSuccess(res)
    if (this.hasLocalStorageSupport) this.clearSavedValues()
  }

  saveValue($input) {
    if (!this.hasLocalStorageSupport) return

    try {
      localStorage.setItem(`formData-${$input.attr('name')}`, $input.val())
    } catch (e) {
      this.hasLocalStorageSupport = false
    }
  }

  restoreSavedValues() {
    if (!this.hasLocalStorageSupport) return

    try {
      this.$inputs.each((_, el) => {
        const $input = $(el)
        const savedValue = localStorage.getItem(`formData-${$input.attr('name')}`)

        if (savedValue) $input.val(savedValue)
      })
    } catch (e) {
      this.hasLocalStorageSupport = false
    }
  }

  clearSavedValues() {
    this.$inputs.each((_, el) => {
      const $input = $(el)
      localStorage.removeItem(`formData-${$input.attr('name')}`)
    })
  }

  async submit(e) {
    e.preventDefault()
    if (this.isLoading) return
    this.setLoading()

    const formData = new FormData(this.$form[0])
    formData.append('form_id', this.id)

    try {
      const recaptcha = await executeRecaptcha()
      formData.append('g-recaptcha-response-data', recaptcha)

      const res = await this.send(formData)
      this.handleSuccess(res)
    } catch (error) {
      console.warn('ERROR sending form:', error)
      if (this.onError) this.onError(error)
    }

    this.setLoading(false)
  }

  send(body) {
    const { submitUrl, method } = this
    return fetch(submitUrl, { mode: 'no-cors', method, body })
      .then(res => res.text())
  }

  setTextareaAutosize() {
    this.$form.find('textarea').each((_, el) => { setTextareaAutosize($(el)) })
  }
}
