const toVideoPlayerOptions = require('../lib/toVideoPlayerOptions')
const VkVideoPlayer = require('./vkVideoPlayer')
const BaseModule = require('_base/base')

class VkVideo extends BaseModule {
  preRender() {
    if (!this.player) {
      const el = this.$el[0]
      const parentModuleClass = el.parentNode.getAttribute('class')
      const options = toVideoPlayerOptions(parentModuleClass)

      this.player = new VkVideoPlayer(el, options)
    }
  }
}

document.querySelectorAll('.vkVideo').forEach(el => new VkVideo($(el)))
