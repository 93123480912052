const YTPlayer = require('../vendor/yt-player')
const VideoPlayerState = require('../lib/videoPlayerState')

class YoutubeVideoPlayer {
  constructor(el, options = {}) {
    if (el.classList.contains('is__initialized')) return

    this.el = el
    this.id = el.getAttribute('data-youtube-id')
    this.playerState = new VideoPlayerState({ id: `yt-${this.id}` })
    this.startTime = parseInt(el.getAttribute('data-youtube-start'), 10)
    this.endTime = parseInt(el.getAttribute('data-youtube-end'), 10)
    this.shouldSaveProgress = options.shouldSaveProgress
    this.shouldSavePlaybackRate = options.shouldSavePlaybackRate

    const playerEl = el.querySelector('.youtubeVideo-container')
    const playerOptions = {
      videoId: this.id,
      start: (this.shouldSaveProgress ? this.playerState.progress : null) || this.startTime,
      end: this.endTime,
      related: false,
    }

    this.player = new YTPlayer(playerEl, playerOptions)
    this.player.load(this.id, playerOptions)
    this.setup()

    this.el.classList.add('is__initialized')
  }

  static vitalize(el) {
    return new YoutubeVideoPlayer(el)
  }

  setup() {
    this.el.addEventListener('playNow', this.player.play.bind(this.player))

    if (this.shouldSavePlaybackRate) {
      this.playbackRate = this.playerState.playbackRate
      this.player.setPlaybackRate(this.playbackRate)

      this.player.on('playbackRateChange', this.savePlaybackRate.bind(this))
      document.addEventListener('playbackRateChange', this.onPlaybackRateChange.bind(this))
    }

    if (this.shouldSaveProgress) {
      this.player.on('timeupdate', this.saveProgress.bind(this))
    }
  }

  onPlaybackRateChange(e) {
    const { playbackRate, id } = e.detail

    if (this.id !== id && playbackRate !== this.playbackRate) {
      this.player.setPlaybackRate(playbackRate)
    }
  }

  saveProgress(seconds) {
    if (!this.duration) this.duration = this.player.getDuration()

    this.playerState.saveProgress({ seconds, duration: this.duration })
  }

  savePlaybackRate(playbackRate) {
    this.playbackRate = playbackRate
    this.playerState.savePlaybackRate(playbackRate)
    this.syncPlaybackRates()
  }

  syncPlaybackRates() {
    const { playbackRate, id } = this
    const event = new CustomEvent('playbackRateChange', { detail: { playbackRate, id } })

    document.dispatchEvent(event)
  }
}

module.exports = YoutubeVideoPlayer
